<template>
  <svg id="svg2" version="1.1" width="672" height="144" viewBox="0 0 672 144"
    sodipodi:docname="Paranza_Logo_Blue_CMYK.eps" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs id="defs6" />
    <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,144)">
      <g id="g10" transform="scale(0.1)">
        <path id="path12"
          d="m 944.586,176.629 h -38.68 v -46.816 h 37.446 c 12.285,0 26.554,9.371 26.554,23.335 0,15.196 -12.59,23.481 -25.32,23.481 z m -0.617,-58.484 H 905.906 V 81.3164 H 891.484 V 188.91 h 52.176 c 19.031,0 40.211,-11.215 40.211,-35.617 0,-22.105 -20.875,-35.148 -39.902,-35.148"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path14"
          d="m 1071.15,171.094 -24.71,-52.785 h 49.42 l -24.56,52.945 z m 30.07,-64.149 h -60.16 l -11.97,-25.6286 h -14.89 l 51.57,107.9026 h 11.82 l 50.64,-107.9026 h -15.19 l -11.82,25.6286"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path16"
          d="m 1229.77,177.387 h -40.67 v -41.133 h 40.82 c 12.28,0 24.25,9.367 24.25,20.566 0,12.59 -11.97,20.567 -24.4,20.567 z m -13.67,-52.946 h -27 V 81.3164 h -14.28 l -0.15,107.5936 h 54.64 c 19.18,0 38.83,-12.137 38.83,-31.93 0,-17.183 -17.2,-31.308 -34.23,-32.07 -0.15,-0.164 -1.23,0 -1.38,-0.164 l 1.54,-1.066 34.99,-40.8284 0.61,-1.5352 h -16.11 c 0,0 -33.3,38.6716 -37.46,43.1246"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path18"
          d="m 1365.53,171.094 -24.71,-52.785 h 49.42 l -24.56,52.945 z m 30.07,-64.149 h -60.15 l -11.98,-25.6286 h -14.88 l 51.57,107.9026 h 11.81 l 50.65,-107.9026 h -15.2 l -11.82,25.6286"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path20"
          d="m 1516.63,177.551 h -33.15 V 92.6758 h 32.84 c 21.49,0 47.59,13.9652 47.59,42.0582 0,28.996 -23.49,42.817 -47.28,42.817 z m 0.61,-96.2346 h -48.18 V 188.746 h 50.34 c 27.47,0 58.47,-18.105 58.47,-53.867 0,-35.6017 -32.54,-53.5626 -60.63,-53.5626"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path22" d="m 1634.43,188.746 h 14.42 V 81.3164 h -14.42 V 188.746"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path24"
          d="m 1750.42,80.0859 c -17.2,0 -32.54,4.6133 -46.96,12.7344 l 5.67,10.6017 c 13.36,-6.9103 27.63,-11.5236 40.52,-11.5236 15.96,0 38.83,5.2266 38.83,20.5666 0,11.976 -15.51,15.195 -29.47,17.203 -4.14,0.453 -8.13,0.902 -11.66,1.52 -21.18,2.929 -43.74,9.207 -43.74,29.015 0,23.949 29.31,30.695 49.72,30.695 17.04,0 32.08,-4.14 45.74,-14.125 l -6.45,-9.351 c -11.51,8.121 -24.71,11.808 -39.29,11.808 -21.33,0 -35.45,-7.523 -35.45,-18.574 0,-9.824 15.04,-13.043 25.32,-15.34 10.9,-2.296 23.64,-3.531 32.23,-5.828 13.97,-3.543 27.47,-10.293 27.47,-27.023 0,-26.0861 -33.61,-32.3791 -52.48,-32.3791"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path26"
          d="m 1852.87,188.746 h 89.79 v -11.359 h -75.21 V 140.25 h 61.7 v -11.195 h -61.7 V 92.8203 h 77.2 V 81.3164 h -91.93 l 0.15,107.4296"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path28" d="m 2086.97,188.746 h 14.42 V 81.3164 h -14.42 V 188.746"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path30"
          d="m 2202.95,80.0859 c -17.19,0 -32.53,4.6133 -46.96,12.7344 l 5.68,10.6017 c 13.35,-6.9103 27.63,-11.5236 40.52,-11.5236 15.96,0 38.82,5.2266 38.82,20.5666 0,11.976 -15.5,15.195 -29.46,17.203 -4.15,0.453 -8.13,0.902 -11.66,1.52 -21.18,2.929 -43.74,9.207 -43.74,29.015 0,23.949 29.31,30.695 49.72,30.695 17.04,0 32.08,-4.14 45.74,-14.125 l -6.45,-9.351 c -11.51,8.121 -24.71,11.808 -39.29,11.808 -21.33,0 -35.45,-7.523 -35.45,-18.574 0,-9.824 15.04,-13.043 25.32,-15.34 10.9,-2.296 23.64,-3.531 32.23,-5.828 13.97,-3.543 27.47,-10.293 27.47,-27.023 0,-26.0861 -33.61,-32.3791 -52.49,-32.3791"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path32" d="m 2305.26,188.746 h 14.42 V 93.2891 h 77.66 V 81.3164 h -92.08 V 188.746"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path34"
          d="m 2495.96,171.094 -24.71,-52.785 h 49.42 l -24.55,52.945 z m 30.08,-64.149 h -60.16 l -11.97,-25.6286 h -14.89 l 51.57,107.9026 h 11.81 l 50.65,-107.9026 h -15.19 l -11.82,25.6286"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path36"
          d="M 2613.76,165.883 V 81.3164 h -14.27 V 188.91 h 9.98 l 84.72,-82.887 v 82.887 h 13.96 V 81.1719 h -7.99 l -86.4,84.7111"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path38"
          d="m 2810.9,177.551 h -33.15 V 92.6758 h 32.84 c 21.49,0 47.59,13.9652 47.59,42.0582 0,28.996 -23.49,42.817 -47.28,42.817 z m 0.61,-96.2346 h -48.19 V 188.746 h 50.35 c 27.47,0 58.47,-18.105 58.47,-53.867 0,-35.6017 -32.54,-53.5626 -60.63,-53.5626"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path40"
          d="m 2920.41,61.0586 -5.06,5.2227 c 3.68,2.9179 11.81,10.2773 11.35,14.582 -0.46,0 -1.07,0.1445 -1.69,0.4531 -4.3,0 -7.52,3.3828 -7.52,7.3594 0,4.7578 4.91,7.8359 9.05,7.8359 7.53,0 9.98,-5.375 9.98,-11.8125 0,-8.5898 -7.67,-18.7187 -16.11,-23.6406"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path42"
          d="m 3128.59,129.668 h -40.36 V 92.5117 h 39.75 c 14.27,0 26.71,5.8438 26.71,18.1253 0,11.504 -11.83,19.031 -26.1,19.031 z m -1.68,48.027 h -38.68 l -0.16,-37.136 39.91,-0.164 c 12.59,0 25.01,6.457 25.01,18.578 0,12.121 -11.65,18.722 -26.08,18.722 z m 3.38,-96.3786 h -56.33 V 188.746 h 52.8 c 20.25,0 40.2,-9.656 40.2,-29.16 0,-12.281 -8.9,-19.645 -18.56,-23.481 v -0.777 c 10.44,-3.219 21.02,-12.734 21.02,-25 0,-20.4217 -21.18,-29.0116 -39.13,-29.0116"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path44"
          d="m 3262.38,171.094 -24.71,-52.785 h 49.42 l -24.57,52.945 z m 30.08,-64.149 h -60.16 l -11.98,-25.6286 h -14.89 l 51.58,107.9026 h 11.81 l 50.65,-107.9026 h -15.2 l -11.81,25.6286"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path46"
          d="m 3449.86,128.582 h -69.23 V 81.3164 H 3365.9 V 188.91 h 14.73 v -48.351 h 69.23 v 48.351 h 14.72 V 81.3164 h -14.72 v 47.2656"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path48"
          d="m 3570.11,171.094 -24.71,-52.785 h 49.42 l -24.56,52.945 z m 30.09,-64.149 h -60.17 l -11.97,-25.6286 h -14.89 l 51.57,107.9026 h 11.81 L 3627.2,81.3164 h -15.19 l -11.81,25.6286"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path50"
          d="m 3790.71,162.969 -0.29,0.617 -45.58,-81.6563 h -6.46 l -41.42,82.2693 -0.78,-0.613 -14.73,-82.2696 h -13.2 l 17.65,107.7386 h 13.19 l 43.29,-85.02 0.14,0.145 48.5,84.875 h 11.52 l 16.43,-107.7386 h -14.13 l -14.13,81.6526"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path52"
          d="m 3918.49,171.094 -24.7,-52.785 h 49.42 l -24.57,52.945 z m 30.09,-64.149 h -60.17 l -11.97,-25.6286 h -14.89 l 51.57,107.9026 h 11.82 l 50.64,-107.9026 h -15.19 l -11.81,25.6286"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path54"
          d="m 4060.68,80.0859 c -17.19,0 -32.53,4.6133 -46.96,12.7344 l 5.68,10.6017 c 13.35,-6.9103 27.62,-11.5236 40.52,-11.5236 15.95,0 38.83,5.2266 38.83,20.5666 0,11.976 -15.5,15.195 -29.46,17.203 -4.14,0.453 -8.14,0.902 -11.67,1.52 -21.18,2.929 -43.74,9.207 -43.74,29.015 0,23.949 29.3,30.695 49.73,30.695 17.02,0 32.07,-4.14 45.73,-14.125 l -6.44,-9.351 c -11.53,8.121 -24.71,11.808 -39.29,11.808 -21.35,0 -35.46,-7.523 -35.46,-18.574 0,-9.824 15.03,-13.043 25.33,-15.34 10.89,-2.296 23.62,-3.531 32.21,-5.828 13.99,-3.543 27.48,-10.293 27.48,-27.023 0,-26.0861 -33.61,-32.3791 -52.49,-32.3791"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path56"
          d="m 162.164,820.406 c 39.906,-13.691 88.484,-15.558 124.914,-16.191 85.004,0 152.668,34.859 152.668,84.027 0,83.399 -109.297,106.438 -178.695,106.438 -29.488,0 -63.324,-2.496 -91.082,-7.473 -4.332,-16.18 -8.672,-40.457 -8.672,-75.93 z M 79.7578,686.602 84.1016,911.277 c 0,82.153 -22.5586,105.803 -22.5586,105.803 h 184.766 c 188.234,0 276.711,-31.119 276.711,-113.271 0,-93.985 -149.204,-125.731 -228.133,-125.731 -52.055,0 -104.098,4.36 -132.723,6.856 l 0.867,-98.332 c 0,-81.547 26.024,-105.192 26.024,-105.192 H 57.2031 c 0,0 22.5547,23.645 22.5547,105.192"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path58"
          d="M 1005.23,740.742 907.219,896.344 881.188,938.043 760.613,740.742 Z M 653.055,661.074 862.973,963.555 c -15.614,19.293 -39.899,36.725 -76.328,52.275 v 1.25 l 137.05,-0.62 c 6.938,-14.32 16.481,-29.878 30.364,-51.663 L 1145.76,671.656 c 18.22,-26.136 49.45,-67.218 81.54,-90.246 h -152.67 c 9.54,16.805 -8.67,58.5 -20.82,80.914 l -32.1,52.278 H 745 L 712.043,661.074 C 706.832,650.492 687.75,612.523 694.695,581.41 H 588.863 c 22.559,26.141 47.711,58.5 64.192,79.664"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path60"
          d="m 1501.12,823.52 c 39.9,-13.696 88.48,-18.672 124.91,-19.305 102.36,0 170.01,34.859 170.01,96.473 0,68.464 -109.29,93.992 -196.04,93.992 -29.48,0 -63.32,-2.496 -91.08,-7.473 -4.33,-16.18 -8.67,-40.457 -8.67,-75.93 z m -82.41,-136.918 4.34,224.675 c 0,82.153 -22.55,105.803 -22.55,105.803 h 184.76 c 205.58,0 294.06,-32.982 294.06,-113.271 0,-82.789 -116.23,-115.149 -209.92,-121.371 l 166.55,-133.805 c 23.42,-20.551 58.99,-41.082 108.43,-61.613 v -4.36 h -131.86 c -19.94,21.164 -52.91,42.942 -75.45,64.106 L 1573.98,783.07 c -29.49,1.231 -55.51,3.727 -72.86,4.977 l 0.87,-101.445 c 0,-81.547 26.02,-105.192 26.02,-105.192 h -131.85 c 0,0 22.55,23.645 22.55,105.192"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path62"
          d="m 2508.24,740.742 -98.02,155.602 -26.03,41.699 -120.57,-197.301 z m -352.18,-79.668 209.92,302.481 c -15.62,19.293 -39.9,36.725 -76.33,52.275 v 1.25 l 137.05,-0.62 c 6.94,-14.32 16.48,-29.878 30.36,-51.663 l 191.7,-293.141 c 18.22,-26.136 49.45,-67.218 81.54,-90.246 h -152.66 c 9.54,16.805 -8.68,58.5 -20.82,80.914 l -32.1,52.278 h -276.71 l -32.96,-53.528 c -5.21,-10.582 -24.3,-48.551 -17.35,-79.664 h -105.83 c 22.56,26.141 47.71,58.5 64.19,79.664"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path64"
          d="m 3000.65,1017.08 381.68,-356.619 0.87,26.141 3.46,224.675 c 0,82.153 -20.81,105.803 -20.81,105.803 h 89.35 c 0,0 -21.69,-23.65 -21.69,-105.803 l 1.73,-224.675 c 0,-44.829 6.93,-72.196 13.88,-87.77 l 18.21,-17.422 H 3365.85 L 2983.3,938.656 c -0.87,-8.086 -0.87,-17.422 -0.87,-27.379 l 1.74,-224.675 c 0,-81.547 23.43,-105.192 23.43,-105.192 h -96.29 c 0,0 20.82,23.645 20.82,105.192 l 3.47,224.675 c 0,38.586 -4.34,64.102 -9.55,80.907 l -26.88,24.896 h 101.48"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path66"
          d="m 3985.76,911.277 c 27.75,26.766 55.51,52.903 76.33,79.664 -144.85,0.618 -336.57,-3.734 -385.13,-59.746 l -6.08,2.485 20.82,85.27 507.44,-1.87 -316.6,-301.861 c -37.3,-36.09 -75.47,-71.563 -104.1,-107.055 146.59,-1.23 352.18,1.25 402.48,59.137 l 6.08,-2.5 -20.82,-85.254 -524.79,2.48 344.37,329.25"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
        <path id="path68"
          d="m 4760.73,740.742 -98.02,155.602 -26.01,41.699 -120.59,-197.301 z m -352.18,-79.668 209.93,302.481 c -15.61,19.293 -39.9,36.725 -76.34,52.275 v 1.25 l 137.05,-0.62 c 6.94,-14.32 16.5,-29.878 30.37,-51.663 l 191.69,-293.141 c 18.22,-26.136 49.46,-67.218 81.55,-90.246 h -152.68 c 9.56,16.805 -8.66,58.5 -20.82,80.914 l -32.09,52.278 H 4500.5 l -32.96,-53.528 c -5.21,-10.582 -24.29,-48.551 -17.34,-79.664 h -105.83 c 22.54,26.141 47.71,58.5 64.18,79.664"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" />
      </g>
    </g>
  </svg>
</template>
