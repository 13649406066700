<script setup lang="ts">
const state = reactive({
  belowFold: false,
  mobileMenuOpen: false,
  menu: null,
})

const openTableWidget: Ref<null | HTMLElement> = ref(null)
watch(openTableWidget, () => {
  if (openTableWidget.value) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.onload = function () {
      // remote script has loaded
    }
    script.src = '//www.opentable.com/widget/reservation/loader?rid=1291345&type=single&theme=tall&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&ot_campaign=Nobu%20Restaurant%20Website'
    openTableWidget.value.appendChild(script)
  }
})

const { data: menu } = await $voixNuxtApi(`/api/sites/10/menus/Main%20navigation%20(top)`)

onMounted(() => {
  state.belowFold = window.scrollY > 500
  window.addEventListener('scroll', () => {
    state.belowFold = window.scrollY > 500
  })
})
</script>

<template>
  <div
    class="hidden lg:block fixed z-40 top-0 left-0 right-0 border-b duration-500 backdrop-blur-sm" :class="{
      'text-paranza-400 bg-white/80 hover:bg-white/90 border-gray-200': state.belowFold,
      'text-white bg-black/50 hover:bg-black/75 border-white': !state.belowFold,
    }"
  >
    <div
      class="container px-4 duration-500 grid grid-cols-12" :class="{
        'py-3 hover:py-5 text-sm': state.belowFold,
        'py-6 hover:py-10': !state.belowFold,
      }"
    >
      <div v-if="menu" class="col-span-5 flex items-center space-x-5">
        <div class="flex items-center space-x-3">
          <a href="https://www.instagram.com/paranzaatlantis/" target="_blank">
            <Icon
              name="mdi:instagram" :class="{
                'w-5 h-5 ': state.belowFold,
                'w-7 h-7 ': !state.belowFold,
              }"
            />
          </a>
        </div>
        <NuxtLink v-if="menu.data.menu_items.length > 0" :to="menu.data.menu_items[0].permalink" class="whitespace-nowrap">
          {{ menu.data.menu_items[0].name }}
        </NuxtLink>
        <NuxtLink v-if="menu.data.menu_items.length > 1" :to="menu.data.menu_items[1].permalink" class="whitespace-nowrap">
          {{ menu.data.menu_items[1].name }}
        </NuxtLink>
      </div>
      <div class="col-span-2 flex justify-center items-center">
        <NuxtLink to="/">
          <RestaurantMicroChromeParanzaLogo
            class="h-12 w-48 duration-200" :class="{
              'h-7 w-30': state.belowFold,
              'h-12 w-48': !state.belowFold,
            }"
          />
        </NuxtLink>
      </div>
      <div class="col-span-5 flex justify-end space-x-8">
        <div v-if="menu" class="flex items-center space-x-5">
          <NuxtLink v-if="menu.data.menu_items.length > 2" :to="menu.data.menu_items[2].permalink" class="whitespace-nowrap">
            {{ menu.data.menu_items[2].name }}
          </NuxtLink>
          <NuxtLink v-if="menu.data.menu_items.length > 3" :to="menu.data.menu_items[3].permalink" class="whitespace-nowrap">
            {{ menu.data.menu_items[3].name }}
          </NuxtLink>
          <NuxtLink v-if="menu.data.menu_items.length > 4" :to="menu.data.menu_items[4].permalink" class="whitespace-nowrap">
            {{ menu.data.menu_items[4].name }}
          </NuxtLink>
          <NuxtLink v-if="menu.data.menu_items.length > 5" :to="menu.data.menu_items[5].permalink" class="whitespace-nowrap">
            {{ menu.data.menu_items[5].name }}
          </NuxtLink>
        </div>

        <div class="relative flex items-center">
          <a
            href="https://www.sevenrooms.com/landing/atlantisparadiseislandbahamas"
            target="_blank"
            class="border py-2 px-3 uppercase text-xs duration-500"
            :class="{
              'border-paranza-400 hover:bg-paranza-400 hover:text-white': state.belowFold,
              'border-white hover:bg-white hover:text-paranza-400': !state.belowFold,
            }"
          >
            Make a reservation
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed lg:hidden z-40 inset-0 pointer-events-none overflow-hidden">
    <div
      class="relative bg-paranza-400/80 flex justify-center py-4 backdrop-blur-sm pointer-events-auto duration-500" :class="{
        'translate-x-[75vw]': state.mobileMenuOpen,
      }"
    >
      <button class="absolute z-50 left-0 top-0 bottom-0 flex items-center pl-4" @click="state.mobileMenuOpen = !state.mobileMenuOpen">
        <Icon name="heroicons-outline:menu" class="w-7 h-7 text-white" />
      </button>
      <RestaurantMicroChromeParanzaLogo class="h-12 w-40 text-zinc-200" />
    </div>

    <div
      class="absolute inset-0 bg-paranza-400 z-40 duration-500  pointer-events-auto"
      :class="{
        '-translate-x-full': !state.mobileMenuOpen,
        '-translate-x-[20vw]': state.mobileMenuOpen,
      }"
    >
      <div class="absolute top-0 bottom-0 translate-x-[20vw] flex flex-col justify-between space-y-8">
        <div class="pt-12 flex flex-col space-y-8">
          <ul v-if="menu" class="text-white pl-12 font-sans2 text-lg flex flex-col space-y-4">
            <li v-for="link in menu.data.menu_items" :key="link.id">
              <NuxtLink :to="link.permalink" class="whitespace-nowrap">
                {{ link.name }}
              </NuxtLink>
            </li>
          </ul>
          <div class="pl-12">
            <a
              href="https://www.sevenrooms.com/landing/atlantisparadiseislandbahamas"
              target="_blank"
              class="border border-white py-2 px-3 uppercase text-xs text-white"
            >
              Make a reservation
            </a>
          </div>
          <ul class="text-zinc-300 pl-12 font-sans2 text-sm flex space-x-4">
            <li>
              <a href="https://www.instagram.com/paranzaatlantis/" target="_blank">
                <Icon name="mdi:instagram" class="w-5 h-5" /> Instagram
              </a>
            </li>
          </ul>
        </div>
        <div class="p-12">
          <RestaurantMicroChromeParanzaLogo class="h-12 w-40 text-zinc-200" />
        </div>
      </div>
    </div>
  </div>
</template>
