<template>
  <div class="pt-12">
    <div class="max-w-[1200px] mx-auto">
      <ParanzaContactForm />
    </div>

    <div class="flex flex-col space-y-8 bg-paranza-400 py-12 w-full ">
      <div class="flex justify-center text-white">
        <RestaurantMicroChromeParanzaLogo class="h-20 w-64" />
      </div>
      <ul class=" flex justify-center text-white" />
    </div>
  </div>
</template>
