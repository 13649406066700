<script setup lang="ts">
import { ErrorMessage, Field, Form } from 'vee-validate'
import { useRuntimeConfig } from '#imports'

// Validation, or use `yup` or `zod`
function required(value: string) {
  return value ? true : 'This field is required'
}

// Create the schema
const schema = {
  Email: required,
  Name: required,
  AgreeToTerms: (value: string) => {
    return value && value.length ? true : 'You must agree to terms'
  },
}

const state = reactive({
  formSubmitted: false,
})

// Submit handler
function onSubmit(values: { Name: string, Email: string, AgreeToTerms: string }) {
  const runtimeConfig = useRuntimeConfig()
  const apiUrl = runtimeConfig.public.voixApiUrl

  const firstName = values.Name.split(' ').slice(0, -1).join(' ')
  const lastName = values.Name.split(' ').slice(-1).join(' ')

  const finalValues = {
    site_id: 10,
    form: 'paranza-footer-newsletter-signup',
    agree_to_terms: 1,
    first_name: firstName,
    last_name: lastName,
    email: values.Email,
    data: {},
  }

  const url = `${apiUrl}/form-signup`
  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(finalValues),
  }
  fetch(url, options)
    .then(response => response.json())
    .then((data) => {
      state.formSubmitted = true
    })
}
</script>

<template>
  <div class="-mb-8">
    <div class="container flex justify-center">
      <div class="w-full bg-gray-100 px-16 py-8 flex flex-col items-center">
        <div class="text-center">
          <div class="text-2xl font-strong text-paranza-400 uppercase tracking-wide">
            Be the first to know of upcoming events and announcements
          </div>
        </div>
        <ClientOnly>
          <Form v-if="!state.formSubmitted" v-slot="{ values }" :validation-schema="schema" class="mt-4 flex flex-col items-center w-full" @submit="onSubmit">
            <div class="w-full flex justify-around">
              <div class="flex flex-col space-y-1 w-2/5">
                <label for="Name" class="text-sm uppercase font-medium text-zinc-400">Name</label>
                <Field id="Name" name="Name" type="text" class="py-2 px-4 text-md text-zinc-700 rounded-sm border border-zinc-300 w-full" />
                <ErrorMessage name="Name" class="py-1.5 px-3 text-red-600 bg-red-200 text-sm rounded" />
              </div>
              <div class="flex flex-col space-y-1 w-2/5">
                <label for="Email" class="text-sm uppercase font-medium text-zinc-400">Email</label>
                <Field id="Email" name="Email" type="text" class="py-2 px-4 text-md text-zinc-700 rounded-sm border border-zinc-300 w-full" />
                <ErrorMessage name="Email" class="py-1.5 px-3 text-red-600 bg-red-200 text-sm rounded" />
              </div>
            </div>
            <div class="mt-8 flex flex-col space-y-1">
              <label for="AgreeToTerms" class="flex space-x-4">
                <Field id="AgreeToTerms" name="AgreeToTerms" type="checkbox" value="agree" class="py-2 px-4 text-md text-zinc-700 rounded-sm border border-zinc-300" />
                <span class="uppercase font-medium text-zinc-400">I am over the age of 18 and have read the Privacy Policy</span>
              </label>

              <ErrorMessage name="AgreeToTerms" class="py-1.5 px-3 text-red-600 bg-red-200 text-sm rounded" />
            </div>

            <div class="mt-2 text-sm text-zinc-400">
              By submitting this form, I confirm I would like to receive emails on promotions, special offers and updates from Atlantis Paradise Island and its affiliated brands.
            </div>

            <div>
              <button
                type="submit"
                class="mt-4 text-sm uppercase font-medium bg-paranza-400 text-white px-4 py-3"
              >
                Submit
              </button>
            </div>
          </Form>

          <div v-else class="mt-6 pb-8 text-paranza-900 text-3xl font-sans2 font-light">
            We have received your submission. Thank you!
          </div>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
