<script setup>
defineLayout({
  label: 'Paranza',
})
useHead({
  meta: [
    { name: 'msapplication-TileColor', content: '#4e6c7f' },
    { name: 'msapplication-config', content: '/favicons/paranza/browserconfig.xml' },
    { name: 'theme-color', content: '#4e6c7f' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/paranza/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/paranza/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/paranza/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/paranza/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/paranza/safari-pinned-tab.svg', color: '#4e6c7f' },
    { rel: 'shortcut icon', href: '/favicons/paranza/favicon.ico' },
  ],
  script: [
    { src: '//fast.wistia.com/assets/external/E-v1.js', async: true },
  ],
})
</script>

<template>
  <div class="relative bg-paranza-50 min-h-screen">
    <ParanzaNavigation />

    <slot />

    <ParanzaFooter />
  </div>
</template>

<style lang="scss">
@import '~/assets/sass/paranza.scss';
</style>
